var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-container",
        [
          _vm.treeShow
            ? _c("CommonTree", {
                attrs: {
                  treeData: _vm.treeData,
                  defaultProps: _vm.defaultProps,
                  searchTitle: _vm.searchTitle,
                  isShowdig: false,
                  showCheckbox: false,
                  "popover-show": false,
                  expandOnClickNode: false,
                  currentNodeKey: _vm.currentNodeKey,
                  defaultExpandedKeys: _vm.defaultExpandedKeys,
                  treeTitle: "组织类型",
                  nodeKey: "id",
                  defaultCheckedKeys: _vm.defaultCheckedKeys,
                },
                on: {
                  "include-down": _vm.includeDown,
                  getNodeClick: _vm.treeNodeClick,
                },
              })
            : _vm._e(),
          _c(
            "el-main",
            { staticStyle: { position: "relative" } },
            [
              _c("head-layout", {
                attrs: {
                  "head-btn-options": _vm.headBtnOptions,
                  "head-title": "安全生产事故情况",
                },
                on: {
                  "head-remove": _vm.headRemove,
                  "head-submit": _vm.headSubmit,
                  "head-add-tabs": _vm.headAdd,
                  "head-reportView": _vm.headReportView,
                },
              }),
              _c("grid-head-layout", {
                ref: "gridHeadLayout",
                attrs: {
                  "grid-head-btn": _vm.gridHeadBtn,
                  "search-columns": _vm.searchColumns,
                },
                on: {
                  "grid-head-search": _vm.gridHeadSearch,
                  "grid-head-empty": _vm.gridHeadEmpty,
                },
              }),
              _c("grid-layout", {
                ref: "gridLayOut",
                attrs: {
                  "grid-row-btn": _vm.gridRowBtn,
                  "table-options": _vm.tableOptions,
                  "table-data": _vm.tableData,
                  "table-loading": _vm.crudLoading,
                  "data-total": _vm.page.total,
                  page: _vm.page,
                },
                on: {
                  "page-current-change": _vm.onLoad,
                  "page-size-change": _vm.onLoad,
                  "page-refresh-change": _vm.onLoad,
                  "gird-handle-select-click": _vm.selectionChange,
                },
                scopedSlots: _vm._u([
                  {
                    key: "customBtn",
                    fn: function ({ row }) {
                      return [
                        row.dataState == "1" || row.dataState == "3"
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.rowEdit(row)
                                  },
                                },
                              },
                              [_vm._v("\n              填报\n            ")]
                            )
                          : _vm._e(),
                        row.dataState == 1 || row.dataState == 3
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.rowRemove(row)
                                  },
                                },
                              },
                              [_vm._v("\n              删除\n            ")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.showDialog
        ? _c(
            "el-dialog",
            {
              directives: [{ name: "dialog-drag", rawName: "v-dialog-drag" }],
              staticClass: "qmDialog",
              attrs: {
                title:
                  this.safeType == "edit"
                    ? "安全生产事故情况报表编辑"
                    : this.safeType == "view"
                    ? "安全生产事故情况报表查看"
                    : "安全生产事故情况报表新增",
                "append-to-body": true,
                "close-on-click-modal": false,
                visible: _vm.showDialog,
                width: "60%",
                fullscreen: _vm.fullscreen,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.showDialog = $event
                },
                close: _vm.globalBoxClose,
              },
            },
            [
              _c("safetyDialog", {
                ref: "safetyDialog",
                attrs: { rowQuery: _vm.rowQuery },
                on: { closeDialog: _vm.closeDialog },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }