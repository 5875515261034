<template>
  <div>
    <el-container>
      <CommonTree
        :treeData="treeData"
        v-if="treeShow"
        :defaultProps="defaultProps"
        :searchTitle="searchTitle"
        treeTitle="组织架构"
        :isShowdig="false"
        :showCheckbox="false"
        :default-expand-all="true"
        :expandOnClickNode="false"
        :currentNodeKey="currentNodeKey"
        :defaultExpandedKeys="defaultExpandedKeys"
        :defaultCheckedKeys="defaultCheckedKeys"
        :nodeKey="'id'"
        @getNodeClick="treeNodeClick"
      />
      <el-main style="position: relative">
        <head-layout
          head-title="境外项目人员信息"
          :head-btn-options="headBtnOptions"
          @head-submit="headSubmit"
          @head-reportView="headReportView"
        ></head-layout>
        <grid-head-layout
          ref="gridHeadLayout"
          :grid-head-btn="gridHeadBtn"
          :search-columns="searchColumns"
          @grid-head-search="gridHeadSearch"
          @grid-head-empty="gridHeadEmpty"
        ></grid-head-layout>
        <grid-layout
          ref="gridLayOut"
          :grid-row-btn="gridRowBtn"
          :table-options="tableOptions"
          :table-data="tableData"
          :table-loading="crudLoading"
          :data-total="page.total"
          :page="page"
          @grid-row-detail-click="rowView"
          @page-current-change="onLoad"
          @page-size-change="onLoad"
          @page-refresh-change="onLoad"
          @gird-handle-select-click="selectionChange"
        >

          <template #customBtn="{row}">
            <el-button
              v-if="row.dataState != '2'"
              type="text"
              @click="rowEdit(row)"
            > 填报
            </el-button>
<!--            <el-button-->
<!--              v-if="row.dataState!='1'&&row.dataState!=''"-->
<!--              type="text"-->
<!--              @click="rowTrackPending(row)"-->
<!--            ><span v-if="row.dataState=='3'">重新填报</span><span v-else>流程详情</span>-->
<!--            </el-button>-->
          </template>
        </grid-layout>
      </el-main>
    </el-container>
    <el-dialog
      :title="this.type == 'edit' ? '境外项目人员信息编辑' : (this.type == 'view' ? '境外项目人员信息查看' : '境外项目人员信息新增')"
      :append-to-body="true"
      :close-on-click-modal="false"
      :visible.sync="overseasProjectFilling"
      width="70%"
      top="5vh"
      @close="overseasDialog"
    >
      <overseasProjectFillingEdit ref="overseasProjectFillingEdit" :rowData="rowData" v-if="overseasProjectFilling"
                                  @overseasDialog="overseasDialog"></overseasProjectFillingEdit>
    </el-dialog>
  </div>
</template>

<script>
import CommonTree from "@/views/components/com_tree";
import GridLayout from "@/views/components/layout/grid-layout";
import HeadLayout from "@/views/components/layout/head-layout";
import CardLayout from "@/views/components/layout/card-layout";
import Template from "@/views/message/template/list";
import {
  getPage,
  reportTree,
  submissionsSave, getListData
} from "@/api/foreignReport/domesticReport";
import draft from "@/views/plugin/workflow/mixins/draft";
import {detail as getProcessDetail,detail, processList as getProcessList} from "@/api/plugin/workflow/process";
import exForm from "@/views/plugin/workflow/mixins/ex-form";
import overseasProjectFillingEdit from "@/views/business/reportManager/fillReport/overseasProjectFilling/edit";
import website from "@/config/website";

export default {
  mixins: [exForm, draft],
  data() {
    return {
      form: {},
      query: {
        affiliationDept: "",
        projectArea: "2",
        templateCode: "overseasCode",//境外编码
      },
      treeShow: false,
      processTemplateKey: "fill_report_overseas",
      formProcess: {
        id: '',
        processId: '',
        paperName: '',
      },
      overseasProjectFilling: false,
      treeReadOnlyFlag: false,
      tableData: [],
      defaultCheckedKeys: [],
      defaultExpandedKeys: [],
      currentNodeKey: '',
      treeLoading: false,
      crudLoading: false,
      type: "",
      rowData: {},
      treeData: [],
      selectionList: [],
      nodeId: '',
      showTreeDialog: false,
      searchTitle: "deptName",
      defaultProps: {
        label: "deptName",
        value: "deptName",
        key: "id",
      },
      formType: '',
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
    };
  },
  watch: {
    defaultCheckedKeys: {
      // immediate: true,
      handler: function (newVal) {
        if (newVal) {
          this.$nextTick(() => {
            // tree树结构点击会加上下面这个类名
            // 如果默认全部展开-那就会关闭
            document.querySelector(".el-tree-node__content").click();
          });
        }
      },
    },
  },
  computed: {
    refresh() {
      return this.$store.state.common.report
    },
    ids() {
      let ids = [];
      this.selectionList.forEach((ele) => {
        ids.push(ele.id);
      });
      return ids.join(",");
    },
    tableOptions() {
      return {
        index: true,
        indexLabel: "序号",
        indexFixed: "left",
        processTemplateKey: "fill_report_overseas",
        formProcess: {
          id: '',
          processId: '',
          paperName: '',
        },
        menuWidth: 140,
        selection: true,
        linklabel: "projectName",
        menuFixed: 'right',
        column: [

          // {
          //   label: "项目编号",
          //   prop: 'projectCode',
          //   align: "left",
          //   overHidden: true,
          //   fixed: 'left',
          //   width: 200,
          // },
          {
            label: "项目名称",
            prop: "projectName",
            align: "left",
            width: 200,
            overHidden: true,
            fixed: 'left',
          },
          {
            label: "统计日期",
            prop: "statDate",
            align: "center",
            width: 120,
            overHidden: true,
            fixed: 'left',
          },
          {
            label: "中方员工总数",
            prop: "chineseCnt",
            align: "right",
            width: 140,
            overHidden: true,
          },
          {
            label: "在册中方职工数",
            prop: "regeditedChineseCnt",
            align: "right",
            width: 140,
            overHidden: true,
          },
          {
            label: "劳务公司派遣中方员工数",
            prop: "dispatchedChineseCnt",
            align: "right",
            width: 140,
            overHidden: true,
          },
          {
            label: "第三方中方技术服务人员数",
            prop: "thirdPartyChineseCnt",
            align: "right",
            width: 140,
            overHidden: true,
          },
          {
            label: "分包商中方员工数",
            prop: "subcontractorChineseCnt",
            align: "right",
            width: 140,
            overHidden: true,
          },
          {
            label: "外籍人员总数",
            prop: "foreignerCnt",
            align: "right",
            width: 140,
            overHidden: true,
          },
          {
            label: "在册外籍职工数",
            prop: "regularForeignerCnt",
            align: "right",
            width: 140,
            overHidden: true,
          },
          {
            label: "其他外籍人员数",
            prop: "otherForeignerCnt",
            align: "right",
            width: 140,
            overHidden: true,
          },
          {
            label: "内部人员增减说明",
            prop: "remark1",
            align: "left",
            width: 140,
            overHidden: true,
          },
          {
            label: "外部人员增减说明",
            prop: "remark2",
            align: "left",
            width: 140,
            overHidden: true,
          },
          {
            label: "业务类型",
            prop: "businessTypeName",
            align: "center",
            width: 140,
            overHidden: true,
          },
          {
            label: "项目状态",
            prop: "projectStatusName",
            type: "select",
            dataType: "number",
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=prj_state",
            dicData: [],
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            align: "center",
            width: 80,
            overHidden: true,
          },
          {
            label: "国别",
            prop: "countryName",
            align: "center",
            width: 140,
            overHidden: true,
          },
          {
            label: "州/省",
            prop: "provinceName",
            align: "center",
            width: 140,
            overHidden: true,
          },
          {
            label: "现场负责人姓名",
            prop: "onsiteDirectorName",
            align: "center",
            width: 140,
            overHidden: true,
          },
          {
            label: "现场负责人职务",
            prop: "onsiteDirectorPost",
            align: "center",
            width: 140,
            overHidden: true,
          },
          {
            label: "现场负责人联系方式",
            prop: "onsiteDirectorContact",
            align: "center",
            width: 140,
            overHidden: true,
          },
          {
            label: "现场负责人卫星电话数量",
            prop: "onsiteDirectorCellphone",
            align: "right",
            width: 140,
            overHidden: true,
          },
          {
            label: "数据来源",
            prop: "dateSource",
            align: "center",
            type: "select",
            dataType: "string",
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=report_data_source",
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            dicData: [],
            width: 80,
          },
          {
            label: '创建时间',
            prop: 'createTime',
            align: "center",
            width: 180,
            overHidden: true
          },
          {
            label: "创建人",
            prop: "createUserName",
            align: "center",
            width: 70,
            overHidden: true,
          },
          {
            label: "填报日期",
            prop: "updateTime",
            align: "center",
            width: 180,
            overHidden: true,
          },
          {
            label: "更新人",
            prop: "updateUserName",
            align: "center",
            width: 70,
            overHidden: true,
          },
          {
            label: "数据状态",
            prop: "dataState",
            align: "center",
            type: "select",
            dataType: "string",
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=data_status_report",
            fixed: 'right',
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            width: 80,
          },
        ],
      };
    },
    gridHeadBtn() {
      return [];
    },
    headBtnOptions() {
      return [
        {
          label: "提交",
          emit: "head-submit",
          type: "button",
          icon: "",
          btnOptType: "loseEfficacy",
        },
        {
          label: "报表查看",
          emit: "head-reportView",
          type: "button",
          icon: "",
          btnOptType: "loseEfficacy",
        },
      ];
    },
    searchColumns() {
      return [
        // {
        //   label: "项目编号,项目名称",
        //   prop: "projectCode",
        //   span: 4,
        //   placeholder: "请输入项目编号或项目名称",
        // },
        {
          label: "业务类型",
          prop: "businessTypeName",
          type: "select",
          dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=report_business_type",
          dicData: [],
          props: {
            label: "dictValue",
            value: "dictKey",
          },
          placeholder: "请选择业务类型",
          span: 4,
        },
        {
          label: "数据状态",
          prop: "dataState",
          type: "select",
          dicUrl: '/api/sinoma-system/dict-biz/dictionary?code=data_status_report',
          dicData: [],
          placeholder: "请选择数据状态",
          props: {
            label: "dictValue",
            value: "dictKey"
          },
          span: 4,
        },
        {
          label: "",
          prop: 'statDate',
          type: "date",
          format: 'yyyy-MM-dd',
          valueFormat: 'yyyy-MM-dd HH:mm:ss',
          placeholder: "请选择统计日期",
          span: 4,
        },
      ];
    },
    gridRowBtn() {
      let buttonBtn = [];
      return buttonBtn;
    },
  },
  components: {
    overseasProjectFillingEdit,
    Template,
    CardLayout,
    GridLayout,
    HeadLayout,
    CommonTree,
  },
  created() {
  },
  mounted() {
    this.initTree()
    // this.getProcessId()
  },
  methods: {
    restRefresh() {
      this.$store.commit('SET_REPORT', !this.refresh)
    },
    receiveData(row) {
      this.defaultExpandedKeys.push(row.fillDeptId)
      this.currentNodeKey = row.fillDeptId;
      this.nodeId = row.fillDeptId;
      if (row.statDate) {
        this.$set(this.$refs.gridHeadLayout.searchForm, 'statDate', row.statDate + ' 00:00:00')
        // this.$refs.gridHeadLayout.searchForm.statDate = row.statDate + ' 00:00:00'
      }
      this.onLoad(this.page);
      this.treeShow = true;
    },
    initData() {
      if (this.treeData.length != 0) {
        this.defaultCheckedKeys.push(this.treeData[0].id)
      }
      // this.onLoad(this.page);
      this.treeShow = true;
    },
    // 批量提交
     headSubmit() {
      if (this.selectionList.length !== 0) {
        let item = [];
        let idList = [];
        this.selectionList.forEach((e) => {
          if (e.id && e.id != -1) {
            idList.push(e.id)
          }
          if (e.dataState !== "1" || e.id === -1 || e.id === undefined || !e.updateUserName) {
            item.push(e);
          }
        });
        let idListString = idList.join(',');
        getListData(idListString).then((res) => {
          if (item.length === 0 && res.data.code == 200 && res.data.data) {
            this.$confirm("确定提交?", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning"
            }).then(() => {
              this.$loading();
              // 遍历 submitData 并更新每个项的 assigneeIds
              this.selectionList.forEach(item => {
                item.updateUserName = this.userInfo.real_name;
                item.dataState = "4";
              });
              submissionsSave(this.selectionList).then((res) => {
                this.$message({
                  type: "success",
                  message: "提交成功",
                });
                this.onLoad(this.page, {});
                this.restRefresh();
              });
          }).catch(() => {
            this.$loading().close();
          }).finally(() => {
            this.$loading().close();
          });
            //   const promises = this.selectionList.map(async (e) => {
            //     this.formProcess.id = e.id;
            //     this.formProcess.deptId = this.nodeId;
            //     this.formProcess.paperName = e.paperName;
            //     const res = await this.handleStartProcess5(true, true);
            //     e.updateUserName = this.userInfo.real_name;
            //     e.processInsId = res.data.data;
            //     e.dataState = 2;
            //     return e; // 返回更新后的项
            //   });
            //
            //   const submitData = await Promise.all(promises); // 等待所有异步操作完成
            //   let assigneeIds = "";
            //   await getProcessDetail({processInsId: submitData[0].processInsId}).then((res) => {
            //     if (res.data.data.flow[0].assigneeIds) {
            //       assigneeIds = res.data.data.flow[0].assigneeIds
            //     }
            //     // 遍历 submitData 并更新每个项的 assigneeIds
            //     submitData.forEach(item => {
            //       item.assigneeIds = assigneeIds;
            //     });
            //     submissionsSave(submitData).then((res) => {
            //       this.$message({
            //         type: "success",
            //         message: "提交成功",
            //       });
            //     });
            //   });
            // }).catch(() => {
            //   this.$loading().close();
            // }).finally(() => {
            //   this.$loading().close();
            // });
          } else {
            this.$message.warning("提交列表中存在已提交或未保存的数据，请重新勾选！");
          }
        })
      } else {
        this.$message.warning("请勾选数据后再进行操作！");
      }
    },
    // 获取流程Id
    getProcessId() {
      getProcessList(1, 100).then((res) => {
        let processList = res.data.data.records
        if (processList.length === 0) {
          this.$message({
            type: "warning",
            message: "流程列表为空，请联系管理员",
          });
          return;
        }
        let process = processList.filter(
          (item) => item.key === this.processTemplateKey
        )[0];
        if (process === undefined) {
          this.$message({
            type: "warning",
            message: "未查询到该流程，请联系管理员",
          });
          return;
        }
        this.submitLoading = false;
        this.tag.label = process.name;
        this.formProcess.processId = process.id;
      });
    },
    async rowTrackPending(row) {
      const {processInsId} = row;
      detail({processInsId: processInsId}).then((res) => {
        const {process} = res.data.data;
        const {id, taskId, processInstanceId, processId, processDefKey} =
          process;
        let param = Buffer.from(
          JSON.stringify({
            processId: id,
            taskId,
            processInsId: processInstanceId || processId,
            processDefKey,
          })
        ).toString("base64");
        //先直接跳转安环侧流程详情页面，因为平台侧页面跳转会出现导航，安环系统已经去除导航
        let sUrl =
          window.location.origin + "#/process/external/iframe/detail?p=" + param + '&isNewWindow=true';
        window.open(sUrl, "_blank");
      });
    },
    initTree() {
      let templateCode = "overseasCode";
      let type = "fill";
      reportTree(templateCode, type,"","")
        .then((result) => {
          this.treeData = result.data.data;
          if (this.treeData == "您不需要填报该报表!") {
            this.$message.warning("您不需要填报该报表!")
            return
          }
          this.query.affiliationDept = this.nodeId;
          if (this.currentNodeKey == '') {
            this.$nextTick(() => {
              this.defaultCheckedKeys.push(this.treeData[0].id)
            })
          }
          this.treeLoading = false;
        })
        .catch((err) => {
          this.treeLoading = true;
        });
    },
    overseasDialog() {
      this.overseasProjectFilling = false;
      this.onLoad(this.page)
    },
    // 点击左侧树节点
    treeNodeClick(node) {
      this.nodeId = node.id;
      this.query.affiliationDept = this.nodeId;
      this.page.currentPage = 1;
      this.onLoad(this.page);
    },
    headReportView() {
      if (this.nodeId) {
        window.open('/#/business/reportManager/reportView/index?isNewWindow=true&deptId=' + this.nodeId + "&reportCode=overseasCode")
      } else {
        this.$message.warning("请先选择组织")
      }
    },
    rowEdit(row) {
      this.overseasProjectFilling = true
      this.type = "edit";
      this.rowData = {
        row: JSON.stringify(row),
        type: 'edit',
        orgCode: this.nodeId
      }
    },
    rowView(row) {
      this.overseasProjectFilling = true
      this.type = "view";
      this.rowData = {
        row: JSON.stringify(row),
        type: 'view',
        orgCode: this.nodeId
      };
    },

    gridHeadSearch() {
      this.page.currentPage = 1;
      this.onLoad(this.page);
    },
    gridHeadEmpty() {
      this.query = {}
      this.onLoad(this.page);
    },
    selectionClear() {
      this.selectionList = [];
    },
    onLoad(page, params = {}) {
      if (this.nodeId) {
        this.page = page;
        this.crudLoading = true;
        this.query.affiliationDept = this.nodeId;
        this.query.projectArea = "2";
        this.query.templateCode = "overseasCode";
        getPage(
          page.currentPage,
          page.pageSize,
          Object.assign(params, this.$refs.gridHeadLayout.searchForm, this.query)
        )
          .then((res) => {
            const data = res.data.data;
            this.page.total = data.total;
            this.tableData = data.records;
            this.crudLoading = false;
            this.selectionClear();
          }).catch(() => {
          this.tableData = []
        }).finally(() => {
          this.crudLoading = false;
        })
      } else {
        this.$message.warning("您不需要填报该报表");
      }
    },
    selectionChange(list) {
      this.selectionList = list;
    },
  },
};
</script>
<style>
</style>

