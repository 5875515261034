var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mainReportBox" },
    [
      _c(
        "div",
        {
          staticClass: "topReportBox",
          staticStyle: {
            height: "46px",
            "background-color": "#F2F3F5",
            "border-bottom": "1px solid rgb(204, 204, 204)",
          },
        },
        [
          _c("div", { staticClass: "leftReportTitle" }, [
            _vm._v("\n        报表填报\n      "),
          ]),
          _c(
            "el-button",
            {
              staticClass: "closeBtn",
              attrs: { size: "medium", icon: "el-icon-close" },
              on: { click: _vm.occlude },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
      _vm.showForm.home
        ? _c("Home", { ref: "Home", on: { runApproval: _vm.runApproval } })
        : _vm._e(),
      _vm.showForm.overseasCode
        ? _c("overseasProjectFilling", { ref: "overseasCode" })
        : _vm._e(),
      _vm.showForm.domesticCode
        ? _c("domesticProjectFilling", { ref: "domesticCode" })
        : _vm._e(),
      _vm.showForm.safetyProductionMonthly
        ? _c("safetyProductionMonthly", { ref: "safetyProductionMonthly" })
        : _vm._e(),
      _vm.showForm.ecologicalProductionMonthly
        ? _c("ecologicalProductionMonthly", {
            ref: "ecologicalProductionMonthly",
          })
        : _vm._e(),
      _vm.showForm.rptAdminPunishStat
        ? _c("securityProtectionSafetyFilling", { ref: "rptAdminPunishStat" })
        : _vm._e(),
      _vm.showForm.governmentDepartmentSafetyProduction
        ? _c("governmentDepartmentSafetyProductionFilling", {
            ref: "governmentDepartmentSafetyProduction",
          })
        : _vm._e(),
      _vm.showForm.safetActionStat
        ? _c("safetActionStat", { ref: "safetActionStat" })
        : _vm._e(),
      _vm.showForm.safeAccidentStat
        ? _c("safeAccidentStat", { ref: "safeAccidentStat" })
        : _vm._e(),
      _vm.showForm.hazardInvestigation
        ? _c("hazardInvestigationFilling", { ref: "hazardInvestigation" })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }