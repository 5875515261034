<template>
  <div>
    <el-container>
      <CommonTree
        :treeData="treeData"
        :defaultProps="defaultProps"
        :searchTitle="searchTitle"
        :isShowdig="false"
        v-if="treeShow"
        :showCheckbox="false"
        :popover-show="false"
        :expandOnClickNode="false"
        :currentNodeKey="currentNodeKey"
        :defaultExpandedKeys="defaultExpandedKeys"
        treeTitle="组织类型"
        :nodeKey="'id'"
        @include-down="includeDown"
        :defaultCheckedKeys="defaultCheckedKeys"
        @getNodeClick="treeNodeClick"
      />
      <el-main style="position: relative">
        <head-layout
          :head-btn-options="headBtnOptions"
          head-title="安全生产月报"
          @head-remove="headRemove"
          @head-submit="headSubmit"
          @head-add-tabs="headAdd"
          @head-reportView="headReportView"
        ></head-layout>
        <grid-head-layout
          ref="gridHeadLayout"
          :grid-head-btn="gridHeadBtn"
          :search-columns="searchColumns"
          @grid-head-search="gridHeadSearch"
          @grid-head-empty="gridHeadEmpty"
        ></grid-head-layout>

        <grid-layout
          ref="gridLayOut"
          :grid-row-btn="gridRowBtn"
          :table-options="tableOptions"
          :table-data="tableData"
          :table-loading="crudLoading"
          :data-total="page.total"
          :page="page"
          @page-current-change="onLoad"
          @page-size-change="onLoad"
          @page-refresh-change="onLoad"
          @gird-handle-select-click="selectionChange"
        >
          <template #customBtn="{row}">
            <el-button
              v-if="row.status == 1 || row.status == 3"
              type="text"
              size="small"
              @click="rowEdit(row)"
            >
              填报
            </el-button>
<!--            <el-button-->
<!--              type="text"-->
<!--              v-if="row.status!=1 && row.status!=''"-->
<!--              @click="rowTrackPending(row)"-->
<!--            ><span v-if="row.status==3">重新填报</span><span v-else>流程详情</span>-->
<!--            </el-button>-->
            <el-button
              type="text"
              size="small"
              v-if="row.status == 1 || row.status == 3"
              @click="rowRemove(row)"
            >
              删除
            </el-button>
          </template>
        </grid-layout>
      </el-main>
    </el-container>
    <el-dialog
      v-dialog-drag
      :title="this.safeType == 'edit' ? '安全生产月报报表编辑' : (this.safeType == 'view' ? '安全生产月报报表查看' : '安全生产月报报表新增')"
      :append-to-body="true"
      :close-on-click-modal="false"
      :visible.sync="showDialog"
      v-if="showDialog"
      width="60%"
      :fullscreen="fullscreen"
      class="qmDialog"
      @close="globalBoxClose"
    >
      <safetyDialog ref="safetyDialog" :rowQuery="rowQuery" @closeDialog="closeDialog"></safetyDialog>
    </el-dialog>
  </div>
</template>

<script>

import CommonTree from "@/views/components/com_tree";
import GridLayout from "@/views/components/layout/grid-layout";
import HeadLayout from "@/views/components/layout/head-layout";
import CardLayout from "@/views/components/layout/card-layout";
import Template from "@/views/message/template/list";
import {
  selectPage,
  remove,
  submissionsSave, getListData
} from "@/api/report/safetyProductionMonthly";
import {detail as getProcessDetail, detail, processList as getProcessList} from "@/api/plugin/workflow/process";
import exForm from "@/views/plugin/workflow/mixins/ex-form";
import draft from "@/views/plugin/workflow/mixins/draft";
import safetyDialog from "@/views/business/reportManager/fillReport/safetyProductionMonthly/safetyDialog";
import {reportTreeAll, reportTree} from "@/api/foreignReport/domesticReport";
import website from "@/config/website";

export default {
  mixins: [exForm, draft],
  data() {
    return {
      defaultExpandedKeys: [],
      currentNodeKey: '',
      treeShow: false,
      nodeId: '',
      form: {},
      query: {},
      rowQuery: {},
      treeReadOnlyFlag: false,
      tableData: [],
      treeLoading: false,
      crudLoading: false,
      treeData: [],
      dicData: [],
      processTemplateKey: "monthlySafetyProductionStatistics",
      formProcess: {
        id: '',
        processId: '',
        paperName: '',
      },
      type: '',
      selectionList: [],
      defaultCheckedKeys: [],
      node: {}, //左侧树node
      fullscreen: false,
      showTreeDialog: false,
      classifyId: '',
      searchTitle: "fullName",
      safeType: "add",
      defaultProps: {
        label: "deptName",
        value: "id",
        key: "id",
      },
      showDialog: false,
      formType: '',
      formList: {},
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      searchColumns: [
        {
          label: "",
          prop: "status",
          align: "center",
          type: "select",
          dicData: [],
          dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=data_status_report",
          props: {
            label: "dictValue",
            value: "dictKey",
          },
          span: 4,
          placeholder: "请选择状态",
        },
        {
          label: "",
          prop: 'dateAndTime',
          type: "month",
          span: 4,
          format: 'yyyy-MM',
          valueFormat: 'yyyy-MM',
          placeholder: "请选择填报年月",
        },
      ]
    };
  },
  watch: {
    defaultCheckedKeys: {
      // immediate: true,
      handler: function (newVal) {
        if (newVal) {
          this.$nextTick(() => {
            // tree树结构点击会加上下面这个类名
            // 如果默认全部展开-那就会关闭
            document.querySelector(".el-tree-node__content").click();
          });
        }
      },
    },
  },
  computed: {
    refresh() {
      return this.$store.state.common.report
    },
    ids() {
      let ids = [];
      this.selectionList.forEach((ele) => {
        ids.push(ele.id);
      });
      return ids.join(",");
    },
    headBtnOptions() {
      return [
        {
          label: "新增",
          emit: "head-add-tabs",
          type: "button",
          icon: "",
          btnOptType: "add",
        },
        {
          label: "提交",
          emit: "head-submit",
          type: "button",
          icon: "",
          btnOptType: "loseEfficacy",
        },
        {
          label: "删除",
          emit: "head-remove",
          type: "button",
          icon: "",
          btnOptType: "delete",
        },
        {
          label: "报表查看",
          emit: "head-reportView",
          type: "button",
          icon: "",
          btnOptType: "loseEfficacy",
        },
      ];
    },
    tableOptions() {
      return {
        index: true,
        indexLabel: "序号",
        menuWidth: 150,
        selection: true,
        indexFixed: "left",
        linklabel: "courseName",
        menuFixed: 'right',
        column: [

          {
            label: "统计月份",
            prop: 'dateAndTime',
            align: "center",
            overHidden: true,
            fixed: 'left',
            width: 90,
          },
          {
            label: "业务类型",
            prop: "statType",
            align: "center",
            type: "select",
            dataType: "string",
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=statistical_categories",
            props: {
              label: "dictValue",
              value: "dictKey",
            },
          },
          {
            label: "安全工时",
            prop: "safeWorkHours",
            align: "right",
            width: 80,
            overHidden: true,
          },
          {
            label: "本月安全生产投入（万元）",
            prop: "safeInvest",
            align: "right",
            width: 140,
            overHidden: true,
          },
          {
            label: "本月组织开展安全培训次数",
            prop: "trainTimes",
            align: "right",
            width: 140,
            overHidden: true,
          },
          {
            label: "本月参加安全培训人数",
            prop: "trainPeopleCnt",
            align: "right",
            width: 140,
            overHidden: true,
          },
          {
            label: "本月检查数",
            prop: "checkCnt",
            align: "right",
            width: 140,
            overHidden: true,
          },
          {
            label: "本月排查出安全隐患数量",
            prop: "hdCnt",
            align: "right",
            width: 140,
            overHidden: true,
          }, {
            label: "其中排查出重大隐患数量",
            prop: "majorHDCnt",
            align: "right",
            width: 140,
            overHidden: true,
          },
          {
            label: "本月整改完成数量",
            prop: "rectifiedHdCnt",
            align: "right",
            width: 140,
            overHidden: true,
          },
          {
            label: "本月应急演练次数",
            prop: "emergencDrillCnt",
            align: "right",
            width: 140,
            overHidden: true,
          },
          {
            label: "数据来源",
            prop: "dateSource",
            align: "center",
            type: "select",
            dataType: "string",
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=data_sources",
            props: {
              label: "dictValue",
              value: "dictKey",
            },
          },
          {
            label: "数据状态",
            prop: "status",
            align: "center",
            type: "select",
            fixed: 'right',
            dataType: "string",
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=data_status_report",
            props: {
              label: "dictValue",
              value: "dictKey",
            },
          },
          {
            label: "创建日期",
            prop: 'createTime',
            type: 'datetime',
            align: "center",
            format: 'yyyy-MM-dd HH:mm:ss',
            width: 180,
          },

          {
            label: "创建人",
            prop: "createName",
            align: "center",
            width: 70,
            overHidden: true,
          },
          {
            label: "填报日期",
            prop: "updateTime",
            type: 'datetime',
            align: "center",
            format: 'yyyy-MM-dd HH:mm:ss',
            width: 180,
          },
          {
            label: "更新人",
            prop: "updateName",
            align: "center",
            width: 70,
            overHidden: true,
          },
        ],
      };
    },
    gridHeadBtn() {
      return [];
    },
    gridRowBtn() {
      return [];
    },

  },
  components: {
    GridLayout,
    HeadLayout,
    CommonTree,
    CardLayout,
    Template,
    safetyDialog
  },
  created() {
    this.initTree()
  },
  mounted() {
    this.classifyId = ''
    // this.getProcessId();
  },
  methods: {
    restRefresh() {
      this.$store.commit('SET_REPORT', !this.refresh)
    },
    receiveData(row) {
      this.defaultExpandedKeys.push(row.fillDeptId)
      this.currentNodeKey = row.fillDeptId;
      this.nodeId = row.fillDeptId;
      this.query.unitCode = this.nodeId;
      if (row.statDate) {
        this.$set(this.$refs.gridHeadLayout.searchForm, 'dateAndTime', row.statDate)
        // this.$refs.gridHeadLayout.searchForm.dateAndTime = row.statDate
      }
      this.onLoad(this.page);
      this.treeShow = true;
    },
    initData() {
      if (this.treeData.length != 0) {
        this.defaultCheckedKeys.push(this.treeData[0].id)
      }
      // this.onLoad(this.page);
      this.treeShow = true;
    },
    // 批量提交
    headSubmit() {
      if (this.selectionList.length !== 0) {
        let item = [];
        let idList = [];
        this.selectionList.forEach((e) => {
          if (e.id && e.id != -1) {
            idList.push(e.id)
          }
          if (e.status !== 1 || e.id === "-1" || e.id === undefined || !e.updateName) {
            item.push(e);
          }
        });
        let idListString = idList.join(',');
        getListData(idListString).then((res) => {
          if (item.length === 0 && res.data.code == 200 && res.data.data) {
            this.$confirm("确定提交?", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning"
            }).then(() => {
              this.$loading();
              // 遍历 submitData 并更新每个项的 assigneeIds
              this.selectionList.forEach(item => {
                item.updateUserName = this.userInfo.real_name;
                item.status = 4;
                item.statType = "SAFE";
                item.dateSource = "fill";
              });
              submissionsSave(this.selectionList).then((res) => {
                this.$message({
                  type: "success",
                  message: "提交成功",
                });
                this.onLoad(this.page, {});
                this.restRefresh();
            });
          }).catch(() => {
            this.$loading().close();
          }).finally(() => {
            this.$loading().close();
          });
            //   const promises = this.selectionList.map(async (e) => {
            //     this.formProcess.id = e.id;
            //     this.formProcess.deptId = this.nodeId;
            //     this.formProcess.paperName = e.paperName;
            //     const res = await this.handleStartProcess5(true, true);
            //     e.updateUserName = this.userInfo.real_name;
            //     e.processInstanceId = res.data.data;
            //     e.status = 2;
            //     e.statType = "SAFE";
            //     e.dateSource = "fill";
            //     return e; // 返回更新后的项
            //   });
            //
            //   const submitData = await Promise.all(promises); // 等待所有异步操作完成
            //   console.log(submitData,"12654654654")
            //   let assigneeIds = "";
            //   await getProcessDetail({processInsId: submitData[0].processInstanceId}).then((res) => {
            //     if (res.data.data.flow[0].assigneeIds) {
            //       assigneeIds = res.data.data.flow[0].assigneeIds
            //     }
            //     // 遍历 submitData 并更新每个项的 assigneeIds
            //     submitData.forEach(item => {
            //       item.assigneeIds = assigneeIds;
            //     });
            //     submissionsSave(submitData).then((res) => {
            //       this.$message({
            //         type: "success",
            //         message: "提交成功",
            //       });
            //     });
            //   });
            // }).catch(() => {
            //   this.$loading().close();
            // }).finally(() => {
            //   this.$loading().close();
            // });
          } else {
            this.$message.warning("提交列表中存在已提交或未保存的数据，请重新勾选！");
          }
        })
      } else {
        this.$message.warning("请勾选数据后再进行操作！");
      }
    },
    // 获取流程Id
    getProcessId() {
      getProcessList(1, 100).then((res) => {
        let processList = res.data.data.records
        if (processList.length === 0) {
          this.$message({
            type: "warning",
            message: "流程列表为空，请联系管理员",
          });
          return;
        }
        let process = processList.filter(
          (item) => item.key === this.processTemplateKey
        )[0];
        if (process === undefined) {
          this.$message({
            type: "warning",
            message: "未查询到该流程，请联系管理员",
          });
          return;
        }
        this.submitLoading = false;
        this.tag.label = process.name;
        this.formProcess.processId = process.id;
      });
    },
    async rowTrackPending(row) {
      const {processInstanceId} = row;
      detail({processInsId: processInstanceId}).then((res) => {
        const {process} = res.data.data;
        const {id, taskId, processInstanceId, processId, processDefKey} =
          process;
        let param = Buffer.from(
          JSON.stringify({
            processId: id,
            taskId,
            processInsId: processInstanceId || processId,
            processDefKey,
          })
        ).toString("base64");
        //先直接跳转安环侧流程详情页面，因为平台侧页面跳转会出现导航，安环系统已经去除导航
        let sUrl =
          window.location.origin + "#/process/external/iframe/detail?p=" + param + '&isNewWindow=true';
        window.open(sUrl, "_blank");
      });
    },
    closeDialog() {
      this.showDialog = false;
      this.onLoad(this.page)
    },
    fullClick() {
      this.fullscreen = !this.fullscreen;
    },
    initTree() {
      let templateCode = "safetyProductionMonthly";
      let type = "fill";
      reportTree(templateCode, type,"","")
        .then((result) => {
          this.treeData = result.data.data;
          if (this.treeData == "您不需要填报该报表!") {
            this.$message.warning("您不需要填报该报表!")
            return
          }
          this.node = this.treeData[0];
          this.query.affiliationDept = this.nodeId;
          if (this.currentNodeKey == '') {
            this.$nextTick(() => {
              this.defaultCheckedKeys.push(this.treeData[0].id)
            })
          }
          this.treeLoading = false;
        })
        .catch((err) => {
          this.treeLoading = false;
        });
    },
    // 点击左侧树节点
    treeNodeClick(node) {
      this.node = node;
      this.nodeId = node.id;
      this.query.unitCode = this.nodeId;
      this.classifyId = this.nodeId;
      this.unitCode = this.nodeId;
      this.page.currentPage = 1;
      this.onLoad(this.page);
    },
    headReportView() {
      if (this.nodeId) {
        window.open('/#/business/reportManager/reportView/index?isNewWindow=true&deptId=' + this.nodeId + "&reportCode=safetyProductionMonthly")
      } else {
        this.$message.warning("请先选择组织")
      }
    },
    includeDown(e) {
      this.page.currentPage = 1;
      this.query.isInclude = e;
      this.onLoad(this.page);
    },
    headRemove() {
      if (this.selectionList.length === 0) {
        this.$message.warning("请至少选择一条数据进行删除");
        return;
      }
      this.$confirm("确定将选择数据删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          try {
            this.selectionList.forEach(item => {
              if (item.status == 2 || item.status == 4) {
                throw new Error("已通过的数据不可删除")
              }
            })
          } catch (e) {
            this.$message.warning("已通过的数据不可删除");
            return;
          }
          remove(this.ids).then(() => {
            this.$message({
              type: "success",
              message: "操作成功",
            });
            this.onLoad(this.page, {});
            this.selectionClear();
          });
        })
        .catch(() => {
        });
    },
    globalBoxClose() {
      this.onLoad(this.page, {unitCode: this.nodeId});
    },
    rowRemove(row) {
      this.$confirm("确定删除数据", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (row.status == 1 || row.status == 3) {
            remove(row.id).then((res) => {
              if (res.data.data) {
                this.onLoad(this.page, {});
                this.$message({
                  type: "success",
                  message: "删除成功",
                });
              } else {
                this.$message.warning("删除失败！")
              }
            });
          } else {
            this.$message.warning("该数据状态下不能删除")
          }

        })
    },
    rowEdit(row) {
      this.showDialog = true
      this.rowQuery = {
        id: row.id,
        row: JSON.stringify(row),
        type: 'edit',
        nodeId: this.nodeId
      }
      this.safeType = "edit"
    },
    // 新增
    headAdd() {
      if (this.nodeId === '') {
        this.$message.warning("请先选择左侧机构")
      } else {
        this.showDialog = true
        this.rowQuery = {
          type: "add",
          unitCode: this.nodeId,
          nodeId: this.nodeId,
          unitName: this.node.deptName
        },
          this.safeType = "add"
      }
    },
    gridHeadSearch() {
      this.page.currentPage = 1;
      this.onLoad(this.page);
    },
    gridHeadEmpty() {
      this.query = {};
      // this.initTree()
      this.onLoad(this.page);
    },
    selectionClear() {
      this.selectionList = [];
    },
    onLoad(page, params = {}) {
      this.page = page;
      if (!this.nodeId) {
        return this.$message.warning("您不需要填报该报表!")
      }
      this.query.unitCode = this.nodeId
      this.crudLoading = true;
      this.query.statType = 'SAFE'
      selectPage(
        page.currentPage,
        page.pageSize,
        Object.assign(params, this.$refs.gridHeadLayout.searchForm, this.query)
      )
        .then((res) => {
          const data = res.data.data;
          this.$refs.gridLayOut.page.total = data.total;
          this.page.total = data.total;
          data.records.forEach(item => {
            if (item.status == -1) {
              item.status = null
            }
            item.safeWorkHours == -1 ? item.safeWorkHours = null : item.safeWorkHours
            item.safeInvest == -1 ? item.safeInvest = null : item.safeInvest
            item.trainTimes == -1 ? item.trainTimes = null : item.trainTimes
            item.trainPeopleCnt == -1 ? item.trainPeopleCnt = null : item.trainPeopleCnt
            item.checkCnt == -1 ? item.checkCnt = null : item.checkCnt
            item.hdCnt == -1 ? item.hdCnt = null : item.hdCnt
            item.rectifiedHdCnt == -1 ? item.rectifiedHdCnt = null : item.rectifiedHdCnt
            item.majorHDCnt == -1 ? item.majorHDCnt = null : item.majorHDCnt
            item.emergencDrillCnt == -1 ? item.emergencDrillCnt = null : item.emergencDrillCnt
          })
          this.tableData = data.records;
          this.crudLoading = false;
          this.selectionClear();
        })
    },
    selectionChange(list) {
      this.selectionList = list;
    },
  },
};
</script>
<style>

</style>


